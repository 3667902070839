import 'regenerator-runtime/runtime';
import axios from 'axios';

const BASE_URL = 'https://api.midzorskiarea.com/';
let isLoggedIn = false;

export const getAllTrackedEmails = async () => {

  try {

    const response = await axios.get(`${BASE_URL}api/email`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`
      }});
    const emails = response.data;
    let i;
    /*<tr>
    <th scope="row">1</th>
    <td>Mark</td>
    <td>Otto</td>
    <td>@mdo</td>
    </tr>*/

    let html = '';
    let isOpened = 'No';
    let SiteVisit = 'No';

    if (emails?.emailTracks) {
      for (i in emails.emailTracks) {

        if (emails.emailTracks[i].IsOpened)
          isOpened = 'Yes';
        else
          isOpened = 'N/A';

        if (emails.emailTracks[i].SiteVisit)
          SiteVisit = 'Yes';
        else
          SiteVisit = 'N/A';

        html += '<tr><th scope="row">' + emails.emailTracks[i].ID +'</th><td>' + emails.emailTracks[i].Email +'</td><td>' + emails.emailTracks[i].CreatedAt +'</td><td>' + isOpened +'</td><td>' + SiteVisit +'</td></tr>';
      }
      document.getElementById('tableBody').innerHTML = '';
      document.getElementById('tableBody').innerHTML = html;
    }
    console.log(emails)

  } catch (errors) {
    console.error(errors);
  }

}

export const login = async (email, password) => {

  try {

    const response = await axios.post(`${BASE_URL}auth/login`, {email: email, password: password}, {headers: { "Content-Type": "application/json" }});
    const login = response.data;

    return login;

  } catch (errors) {
    console.error(errors);
  }

}

const form = document.querySelector('form');

form.addEventListener('submit', async event => {
  event.preventDefault();

  const loginDetails = await login(document.querySelector('#email').value, document.querySelector('#password').value);
  localStorage.setItem("jwt", loginDetails.jwt);
  localStorage.setItem("user", JSON.stringify(loginDetails.user));
  document.getElementById('loginFOrm').style.display = 'none';
  document.getElementById('mainContent').style.display = 'flex';
  getAllTrackedEmails();
});

if (localStorage.getItem('jwt')) {
  document.getElementById('loginFOrm').style.display = 'none';
  document.getElementById('mainContent').style.display = 'flex';
  getAllTrackedEmails();
}

